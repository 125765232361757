<template>
  <div class="card shadow-sm">
    <div class="card-body">
      <h5 class="card-title">Ultimos 5 movimientos</h5>
      <div class="container">
        <template v-if="hasTransactions">
          <div
            class="row"
            v-for="(transaction, index) in finalTransactions"
            :key="index">
            <div class="col-9">
              <span class="font-weight-bold">{{ transaction.fecha_Transaccion }} -</span>
              <span class="text-nowrap font-weight-normal"> {{ transaction.comercio }}</span>
            </div>
            <div class="col-3 text-right">
              <span class="font-weight-normal">{{ transaction.monto_Transaccion }}</span>
            </div>
          </div>
        </template>
        <template v-else>
          <p class="text-info">No hay transacciones</p>
          <v-icon icon="md:home"></v-icon>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import utilsSerive from '@/services/utils.service.js'
export default {
  name: "TransactionsWidget",
  data() {
    return {
      nombre: 'hola'
    }
  },
  props: {
    transactions: {
      type: Array,
      default: [],
    }
  },
  computed: {
    hasTransactions() {
      return this.transactions.length > 0;
    },
    finalTransactions() {
      return this.transactions.map(t => {
        return {
          ...t,
          fecha_Transaccion: t.fecha_Transaccion.replaceAll('-','/'),
          monto_Transaccion: utilsSerive.formatCurrency(t.monto_Transaccion) 
        }
      })
    }
  },
};
</script>
<style scoped>
.row {
  padding: 0px;
  margin-top: 0px;
}
</style>
