<template>
  <div class="container shadow-sm">
    <template>
      <div class="row d-flex info-icon info-important border rounded-lg p-3">
        <div class="d-flex align-items-center mb-0">
          <i class="m-menu__link-icon material-symbols-outlined">Warning</i>
          <span>Fecha de actualización de información de tu tarjeta:
            <span class="date">{{ fechaActualizacionInfo }}</span>
          </span>
        </div>
    </div>
    <div class="row items">
      <div class="col-sm-6 d-flex flex-column item">
        <div class="d-flex item-icon">
          <i class="m-menu__link-icon material-symbols-outlined">price_check</i>
          <span class="font-weight-bold">Valor Pago Mínimo:</span>
        </div>
        <div class="value important">{{ valorPagoMinimo }}</div>
      </div>

      <div class="col-sm-6 d-flex flex-column item">
        <div class="d-flex item-icon">
          <i class="fas fa-hand-holding-usd"></i>
          <span class="font-weight-bold">Cupo Disponible:</span>
        </div>
        <div class="value">{{ cupoDisponible }}</div>
      </div>

      <div class="col-sm-6 d-flex flex-column item">
        <div class="d-flex item-icon">
          <i class="m-menu__link-icon material-symbols-outlined">Paid</i>
          <span class="font-weight-bold">Valor Pago Total:</span>
        </div>
        <div class="value important">{{ valorPagoTotal }}</div>
        <v-icon icon="md:home"></v-icon>
      </div>

      <div class="col-sm-6 d-flex flex-column item">
        <div class="d-flex item-icon">
          <i class="m-menu__link-icon material-symbols-outlined">Payments</i>
          <span class="font-weight-bold">Cupo Total:</span>
        </div>
        <div class="value">{{ cupoTotal }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div 
        class="item-icon justify-content-between d-sm-flex d-md-block"
        :class="{'saldo-en-mora': conRestricciones}">
          <div class="d-flex">
            <i class="m-menu__link-icon material-symbols-outlined">calendar_clock</i>
              <span class="descriptions">
              Fecha límite de pago:
              </span>
          </div>
          <div class="mx-auto"
          :class="{'d-flex justify-content-center': conRestricciones}">
            <span class="date date-card important">{{ conRestricciones ? "⚠️ Inmediato" :fechaLimitePago }}</span>
          </div>
          <div>
          </div>
        </div>
      </div>
    <!-- </div>
    <div class="row"> -->
      <div class="col-sm-12 col-md-6">
        <div class="item-icon justify-content-between d-sm-flex d-md-block">
          <div class="d-flex">
            <i class="m-menu__link-icon material-symbols-outlined">calendar_clock</i>
              <span class="descriptions">
              Fecha de corte:
              </span>
          </div>
          <div class="">
            <span class="date date-card">{{ fechaCorte }}</span>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
    </template>
  </div>
</template>


<script>
  import { mapState, mapGetters } from 'vuex'
  import utilsSerive from '@/services/utils.service.js'
export default {
  name: "InfoTarjetaCard",
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      tarjeta: state => state.tdr.tarjeta
    }),
    ...mapGetters({
      conSaldoMora: 'tdr/conSaldoMora',
      pagoAtrasado : 'tdr/pagoAtrasado',
      conMoraTemprana: 'tdr/conMoraTemprana'
    }),
    conRestricciones() {
      return this.conSaldoMora || this.pagoAtrasado || this.conMoraTemprana
    },
    cupoTotal() {
      return this.tarjeta.cupoTotal === null ? utilsSerive.formatCurrency(0) :
      utilsSerive.formatCurrency(this.tarjeta.cupoTotal)
    },
    cupoDisponible() {
      if (this.conSaldoMora) {
        return utilsSerive.formatCurrency(0)
      }
      return this.tarjeta.cupoDisponible === null ? '' : 
      utilsSerive.formatCurrency(parseFloat(this.tarjeta.cupoDisponible))
    },
    valorPagoMinimo() {
      if (!this.conSaldoMora){
        return this.tarjeta.valorPagoMinimo === null ? '' : 
        utilsSerive.formatCurrency(parseFloat(this.tarjeta.valorPagoMinimo))
      } else {
        return this.tarjeta.valorPagoMinimo === null ? utilsSerive.formatCurrency(this.saldoMora): 
        utilsSerive.formatCurrency(parseFloat(this.tarjeta.valorPagoMinimo)+this.saldoMora)
      }

    },
    valorPagoTotal() {
      if (!this.conSaldoMora){
        return this.tarjeta.valorPagoTotal === null ? '' : 
        utilsSerive.formatCurrency(parseFloat(this.tarjeta.valorPagoTotal))
      } else {
        return this.tarjeta.valorPagoTotal === null ? utilsSerive.formatCurrency(this.saldoMora): 
        utilsSerive.formatCurrency(parseFloat(this.tarjeta.valorPagoTotal)+this.saldoMora)
      }
    },
    fechaActualizacion() {
      if(this.tarjeta.fechaActualizacionInformacion === null){
        return utilsSerive.fechaFormateada(new Date())
      } else{
        return this.tarjeta.fechaActualizacionInformacion
      }
    },
    fechaCorte() {
      // return this.tarjeta.fechaCorte === null ? '' : 
      // utilsSerive.fechaFormateadaLarga(this.tarjeta.fechaCorte+"T12:00:00")
      return this.calcularFechaCorte(this.fechaActualizacion)
    },
    fechaLimitePago() {
      return this.tarjeta.fechaLimitePago === null ? '' : 
      utilsSerive.fechaFormateadaLarga(this.tarjeta.fechaLimitePago)
    },
    fechaActualizacionInfo() {
      return this.fechaActualizacion === null ? '' : 
      utilsSerive.fechaFormateadaLarga(this.fechaActualizacion)
    },
    saldoMora() {
      return this.tarjeta.saldoMora
    }
  },
  methods: {
    calcularFechaCorte(fechaActualizacion){
      if(!fechaActualizacion) return ''
      const diaFechaCorte = 17
      const fecha = new Date(fechaActualizacion)
      const diaActualizacion = fecha.getDate();
      const mesActualizacion = fecha.getMonth();
      const anioActualizacion = fecha.getFullYear();
      let fechaCorte;
      if (diaActualizacion <= diaFechaCorte){
        fechaCorte = new Date(anioActualizacion, mesActualizacion -1, diaFechaCorte)
      } 
      else fechaCorte = new Date(anioActualizacion, mesActualizacion, diaFechaCorte)

      //Ajustar la fecha si el mes anterior es dicimebre del año ano anterior
      if (mesActualizacion === 0 && diaActualizacion <= diaFechaCorte) {
        fechaCorte = new Date(anioActualizacion, 11, diaFechaCorte)
      }

      return utilsSerive.fechaFormateadaLarga(fechaCorte)
    }
  }
};
</script>

<style scoped>

.saldo-en-mora {
  background-color: rgba(234, 26, 26, 0.816);
  color: white;
  padding: 6px;
  border-radius: 8px;
  justify-content: center;
}
.saldo-en-mora i {
  color: white;
}
.saldo-en-mora .date {
  padding: 3px 15px;
  background-color: rgba(30, 30, 30, 0.9);
  color: white;
  border-radius: 15px;
}
.saldo-en-mora .important {
  display: inline-block;
  text-align: center; /* Añadir esto para centrar el texto en el span */
}
.container {
  font-size: 1.1rem;
  width: 95%;
}
i {
  font-size: 2.2rem;
  color: grey;
}

.d-flex {
  gap: 0.5em;
}
.d-md-flex {
  gap: 0.5em;
}

.d-flex.flex-column {
  gap:0;
}
.date {
  font-weight: bold; /* Texto en negrita */
  color: black; /* Color negro */
}
.date-card {
  padding-left: 38px;
}

.value {
  font-weight:400; /* Texto en negrita */
  font-size: 1rem;
  padding-left: 40px;
}
.value.important, .date.important {
  font-size: 1.5rem;
}
.info-important {
  background-color: rgba(146, 225, 234, 0.668);
}
.descriptions {
  font-weight: 400;
}
.item {
  padding-bottom: 5px;
}
</style>