<template>

    <img v-if="img"
      src="../../assets/img/logos/qntpdf.png"
      v-on:click="redirect_payment($event)"
      id="btn-payment"
    />

    <button v-else
      class="btn btn-primary  bouton-image monBouton"
      v-on:click="redirect_payment($event)"
      id="btn-payment"
    >{{label}}</button>
</template>

<script>
export default {
  name: "PaymentButton",
  props:{
    img:{
      type:Boolean,
      default:false
    },
    url: {
      type:String,
      default: "https://www.psepagos.co/PSEHostingUI/ShowTicketOffice.aspx?ID=7104",
    },
    label:{
      type:String,
      default: "Instrucciones de pago",
    },
    imgScr : {
      type:String,
      default: "../../assets/img/logos/icon-pse.png",
    },

  },
  methods: {
    redirect_payment: function(event) {
      window.open(
        this.url,
        "_blank"
      );
    }
  }
};
</script>

<style scoped>
img{
    /* max-width: 110px;
    min-width: 90px; */
    max-width: 55px;
    min-width: 50px;
    margin-left: 10px;
    width: 100%;
    cursor: pointer;
}
</style>


